// LandingContent.js
'use strict';
import { connect } from 'react-redux';

import LandingContent from '../component/LandingContent.jsx';

import login from '../action/login.js';

import getModalData from '../selector/getModalData.js';
import getRemoteConfigData from '../selector/getRemoteConfigData.js';

import {
  BRANDING_NAME,
  LANDING_MAIN_HEADER_IMAGE,
  LANDING_SECTION1_IMAGE,
  LANDING_SECTION2_IMAGE,
  LANDING_SECTION3_IMAGE,
} from '../RemoteConfigKeys.js';

const mapStateToProps = state => {
  const status = getModalData(state, 'LandingPageModal', 'status');
  return {
    status,
    brandname: getRemoteConfigData(state, BRANDING_NAME),
    mainHeaderImage: getRemoteConfigData(state, LANDING_MAIN_HEADER_IMAGE),
    section1Image: getRemoteConfigData(state, LANDING_SECTION1_IMAGE),
    section2Image: getRemoteConfigData(state, LANDING_SECTION2_IMAGE),
    section3Image: getRemoteConfigData(state, LANDING_SECTION3_IMAGE),
  };
};

const mapDispatchToProps = dispatch => {
  return {
    login: () => dispatch(login()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(LandingContent);
